.cc-form {
    margin-left: 0;
    width: 100%;
}

.cc-form > button {
    width: 100%;
}

@media screen and (min-width: 768px) {
    .cc-form {
        margin: 0 auto;
        width: 50%;
    }
}

@media screen and (min-width: 1200px) {
    .cc-form {
        margin: 0 auto;
        width: 40%;
    }
}