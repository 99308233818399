.card.override-projects {
    border-radius: 0 !important;
    width: 90%;
}

.card-img.override-projects {
    border-radius: 0 !important;
    height: 260px;
}

.cc-img-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    transition: .5s ease;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
}

.cc-img-overlay:hover {
    opacity: 1;
}

.cc-text-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    color: white;
    font-size: 1.15em;
    transition: .5s ease;
    text-align: center;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 768px) {
    .card.override-projects {
        width: 70%;
    }

    .card-img.override-projects {
        height: 360px;
    }

    .cc-text-overlay {
        font-size: 1.4em;
    }
}

@media screen and (min-width: 992px) {
    .card.override-projects {
        width: 90%;
    }
}

@media screen and (min-width: 1200px) {
    .card.override-projects {
        width: 80%;
    }
}

.cc-img-wrapper {
    position: relative;
}






