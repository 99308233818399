.cc-text-fade {
    opacity: 0;

    -webkit-transition: opacity 2s ease-in;
    -moz-transition: opacity 2s ease-in;
    -o-transition: opacity 2s ease-in;
    transition: opacity 2s ease-in;
}

.cc-text-fade.show {
    opacity: 1;
}

.cc-flag {
    border-bottom: black 1px;
}

.nav-link.override-flag {
    cursor: pointer;
}