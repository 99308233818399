.cc-arrow {
    color: white;
    opacity: 0.5;
    cursor: pointer;

    -webkit-transition: opacity 0.15s ease-in;
    -moz-transition: opacity 0.15s ease-in;
    -o-transition: opacity 0.15s ease-in;
    transition: opacity 0.15s ease-in-out;
}

.cc-arrow:hover {
    opacity: 1;
}

.cc-profile-picture {
    /*border: 3px solid black;*/
    border-radius: 50%;
    width: 30%;
}

@media screen and (min-width: 768px) {
    .cc-profile-picture {
        width: 20%;
    }
}

@media screen and (min-width: 992px) {
    .cc-profile-picture {
        width: 15%;
    }
}