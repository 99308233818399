.card.override-skills {
    border-radius: 0 !important;
}

.card-img.override-skills {
    height: 120px;
    -webkit-filter: grayscale(90%);
    filter: grayscale(90%);
}

@media screen and (min-width: 768px) {
    .card-img.override-skills {
        height: 135px;
    }
}

@media screen and (min-width: 992px) {
    .card-img.override-skills {
        height: 170px;
    }
}
