.cc-link-removal {
    text-decoration: none;
}

.cc-icon-size {
    font-size: 0.88em;
}

.cc-screen-text {
    text-align: center;
}

.cc-screen-button {
    display: flex;
    justify-content: center;
}

.cc-width-button-en {
    width: 100px;
}

.cc-width-button-es {
    width: 110px;
}

.cc-margin-end {
    margin-right: 10px;
}

@media screen and (min-width: 992px) {
    .cc-screen-text {
        text-align: start;
    }

    .cc-screen-button {
        display: flex;
        justify-content: start;
    }
}

